import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { components } from "react-select";
dayjs.locale("es");

export default function Terapia() {
  const { getTerapia, terapia, usuario, getDisponibles, disponibles,editEvento,editresponse,cancelarEvento,cancelarresponse } =
    useContext(UsuarioContext);

  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [scancel, setScancel] = useState(false);

  const [nEvento, setnEvento] = useState({
    fecha: "",
    inicio: "",
    fin: "",
    id: "",
    terapeuta: "",
    nota: "",
  });
  const EventoCambia = (e) => {
    setnEvento({ ...nEvento, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShow(!show);
    setnEvento({
      ...nEvento,
      fecha: dayjs(terapia[0].inicio).format("YYYY-MM-DD"),
      inicio: dayjs(terapia[0].inicio).format("HH:mm:ss"),
      fin: dayjs(terapia[0].fin).format("HH:mm:ss"),
      id: terapia[0].id,
      terapeuta: terapia[0].id_terapeuta,
      nota: "",})
  };


  const handleCloseCancel = () => {
    setScancel(!scancel);
    setnEvento({
      ...nEvento,
      fecha: dayjs(terapia[0].inicio).format("YYYY-MM-DD"),
      inicio: dayjs(terapia[0].inicio).format("HH:mm:ss"),
      fin: dayjs(terapia[0].fin).format("HH:mm:ss"),
      id: terapia[0].id,
      terapeuta: terapia[0].id_terapeuta,
      nota: "",})
  };

  const cancelar = () => {
    setScancel(!scancel);
    cancelarEvento(nEvento);
    getTerapia({ id: id });
  };



  const guardar = () => {
    setShow(!show);
    editEvento(nEvento);
    getTerapia({ id: id });
  };

  const regresar = () => {
    return () => {
      window.location.href = `/`;
    };
  };

  const botones = () => {
    if (usuario[0].rol === 3) {
      return (
        <div className="d-flex justify-content-center  ">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="primary"
              className="fs-6 fw-semibold p-1 m-0"
              onClick={regresar()}
            >
              Regresar
            </Button>
            <Button
              variant="primary"
              className="fs-6 fw-semibold p-1 m-0"
              onClick={handleClose}
            >
              Modificar
            </Button>
            <Button variant="primary" className="fs-6 fw-semibold p-1 m-0" onClick={handleCloseCancel}>
              Cancelar
            </Button>
          </ButtonGroup>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example">
            <Button variant="primary" className="fs-6 fw-semibold">
              Regresar
            </Button>
          </ButtonGroup>
        </div>
      );
    }
  };

  useEffect(() => {
    getTerapia({ id: id });
    getDisponibles(nEvento);
  }, []);

  useEffect(() => {
    if (terapia.length > 0) {
      setnEvento({
        ...nEvento,
        fecha: dayjs(terapia[0].inicio).format("YYYY-MM-DD"),
        inicio: dayjs(terapia[0].inicio).format("HH:mm:ss"),
        fin: dayjs(terapia[0].fin).format("HH:mm:ss"),
        id: terapia[0].id,
        terapeuta: terapia[0].id_terapeuta,
        nota: "",
      });
    }
  }, [terapia]);

  console.log(nEvento);

  if (terapia.length > 0) {
    return (
      <div className="d-flex justify-content-center  m-1 p-1 pt-5 mt-5 ">
        <Card style={{ width: "30rem" }}>
          <Card.Header variant="info" className="fs-5 fw-bolder">
            Información de la Terapia
          </Card.Header>
          <ListGroup variant="flush">
          <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Estatus
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].estatus}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Paciente
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].paciente}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Diagnostico
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].diagnostico}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Terapia
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].terapia}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Terapeuta Asignado
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].terapeuta}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Fecha y hora de inicio
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].inicio}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              Fecha y hora de fin
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].fin}
            </ListGroup.Item>
            <ListGroup.Item variant="primary" className="fs-6 fw-semibold">
              No Sesión
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {terapia[0].n_sesion}
            </ListGroup.Item>
            <ListGroup.Item className="fs-5 fw-bolder">
              {botones()}
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            className="fs-6 fw-bolder  p-1 m-0 bg-warning text-white"
          >
            <Modal.Title>Modificar Terapeuta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center p-1 m-1">
                  <Row className="justify-content-center">
                    <Col md={6}>
                      <label className="fs-6 fw-semibold text-center">
                        Paciente
                      </label>
                      <Form.Control
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={terapia[0].paciente}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <label className="fs-6 fw-semibold text-center">
                        Fecha
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].inicio).format("DD/MMMM/YYYY")}
                      />
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      <label className="fs-6 fw-semibold text-center">
                        Inicia
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].inicio).format("HH:mm A")}
                      />
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      <label className="fs-6 fw-semibold text-center">
                        Termina
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].fin).format("HH:mm A")}
                      />
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <label className="fs-6 fw-semibold text-center">
                        Terapeutas disponibles
                      </label>
                      <Form.Select
                        aria-label="Default select example"
                        name="terapeuta"
                        id="terapeuta"
                        className="fs-6 fw-semibold p-1 m-0"
                        value={nEvento.terapeuta}
                        onChange={EventoCambia}
                      >
                        {disponibles.map((elemento) => {
                          return (
                            <option key={elemento.id} value={elemento.id}>
                              {elemento.nombre}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                      <label className="fs-6 fw-semibold text-center">
                        Nota
                      </label>
                      <Form.Control
                        as="textarea" 
                        rows={3}
                        name="nota"
                        id="nota"
                        placeholder="Nota"
                        aria-label="Nota"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        value={nEvento.nota}
                        onChange={EventoCambia}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="p-0 m-0">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="p-1 m-0 w-50"
            >
              Cerrar
            </Button>
            <Button
              variant="primary"
              onClick={guardar}
              className="p-1 m-0 w-50"
            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={scancel} onHide={handleCloseCancel} centered>
          <Modal.Header
            closeButton
            className="fs-6 fw-bolder  p-1 m-0 bg-warning text-white"
          >
            <Modal.Title>Cancelar Terapia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center p-1 m-1">
                  <Row className="justify-content-center">
                    <Col md={6}>
                      <label className="fs-6 fw-semibold text-center">
                        Paciente
                      </label>
                      <Form.Control
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={terapia[0].paciente}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <label className="fs-6 fw-semibold text-center">
                        Fecha
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].inicio).format("DD/MMMM/YYYY")}
                      />
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      <label className="fs-6 fw-semibold text-center">
                        Inicia
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].inicio).format("HH:mm A")}
                      />
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      <label className="fs-6 fw-semibold text-center">
                        Termina
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Paciente"
                        aria-label="Paciente"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={dayjs(terapia[0].fin).format("HH:mm A")}
                      />
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <label className="fs-6 fw-semibold text-center">
                        Terapeuta
                      </label>
                      <Form.Control
                        placeholder="Terapeuta"
                        aria-label="Terapeuta"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        readOnly
                        value={terapia[0].terapeuta}
                      />
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                      <label className="fs-6 fw-semibold text-center">
                        Nota
                      </label>
                      <Form.Control
                        as="textarea" 
                        rows={3}
                        name="nota"
                        id="nota"
                        placeholder="Nota"
                        aria-label="Nota"
                        aria-describedby="basic-addon1"
                        className="fs-6 fw-semibold p-1 m-0"
                        value={nEvento.nota}
                        onChange={EventoCambia}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="p-0 m-0">
            <Button
              variant="secondary"
              onClick={handleCloseCancel}
              className="p-1 m-0 w-50"
            >
              Cerrar
            </Button>
            <Button
              variant="primary"
              onClick={cancelar}
              className="p-1 m-0 w-50"
            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <div>cargando</div>;
  }
}
