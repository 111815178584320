import { useEffect, useContext, useState, useCallback } from "react";

import UsuarioContext from "../context/usuarios/UsuarioContext.js";

import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEvento from "../componentes/addevento.jsx";
import CheckEvento from "../componentes/checkevento.jsx";
import { Link,  useNavigate } from "react-router-dom";


dayjs.locale("es");

const localizer = dayjsLocalizer(dayjs);

export default function Calendario() {
  const { eventos, usuario, getEventos } = useContext(UsuarioContext);
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [addeve, setaddeve] = useState(false);
  const [check, setcheck] = useState(false);
  const [paciente, setpaciente] = useState(0);
  const [terapia, setterapia] = useState("");
  const [aparato, setaparato] = useState("");
  const [evento, setevento] = useState([]);
  const navigate = useNavigate();
  // const eventPropGetter = useCallback(
  //   (event, start, end, isSelected) => (
  //     {
  //       background: event.color,
  //   }),[])
  
  const eventPropGetter = (event, start, end, isSelected) => ({
    ...(dayjs(start).hour() < 20 && {
      style: {
        backgroundColor: event.color,
        border: '1px solid #000',
        color: '#000',
        fontSize: '10px',
      },

    
  
  }),
});
  
  useEffect(() => {
    if (usuario.length > 0) {
      getEventos(usuario[0].id, usuario[0].rol);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let agenda = [];
  if (eventos && eventos.length > 0) {
    eventos.forEach(function (element) {
      agenda.push({
        title: element.paciente,
        start: dayjs(element.start).toDate(),
        end: dayjs(element.end).toDate(),
        paciente: element.paciente,
        terapia: element.terapia,
        aparato: element.aparato,
        diagnostico:element.diagnostico,
        doctor:element.doctor,
        id:element.id,
        color:element.color
      });
    });
  }

  // const handleSelectEvent = (e) => {
  //   setcheck(true);
  //   setaddeve(false);
  //   setevento(e)
  // };

  const handleSelectEvent = (e) => {

    navigate(`/terapia/${e.id}`);
  
   };


  const handleSelectSlot = (e) => {
    setpaciente(0);
    setstart(e.start);
    setend(e.end);
    setcheck(false);
    setaddeve(true);
  };
  if (usuario.length > 0) {
    if (usuario[0].rol !== 3) {
      return (
        <div>
          <div className="d-flex justify-content-center p-0 m-0 pt-5 mt-5">
            <Calendar
              localizer={localizer}
              events={agenda}
              startAccessor="start"
              endAccessor="end"
              views={["day", "week", "month"]}
              min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
              max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
              defaultView="day"
              eventPropGetter={eventPropGetter}
              step={30}
              style={{ height: "65vh", width: "80vw" }}
              selectable
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              // onSelecting={handleSelectEvent}
              messages={{
                next: "Siguiente",
                previous: "Anterior",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              popup
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="d-flex justify-content-center pt-5 mt-5">
            <Calendar
              localizer={localizer}
              events={agenda}
              startAccessor="start"
              endAccessor="end"
              views={["day", "week", "month"]}
              min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
              max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
              defaultView="day"
              step={30}
              eventPropGetter={eventPropGetter}
              style={{ height: "85vh", width: "95vw" }}
              selectable
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              // onSelecting={handleSelectEvent}
              messages={{
                next: ">>",
                previous: "<<",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              popup
            />
          </div>
          {check ? (
            <CheckEvento
              paciente={paciente}
              terapia={terapia}
              aparato={aparato}
              start={start}
              end={end}
              usuario={usuario}
              getEventos={getEventos}
              evento ={evento}
              setcheck={setcheck}
            />
          ) : (
            ""
          )}

          {addeve ? (
            <AddEvento start={start} end={end} setaddeve={setaddeve} />
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}
