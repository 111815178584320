import React, { useContext, useEffect, useState } from "react";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import Diagnosticos from "../componentes/diagnosticos";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import {
  faPencil,
  faUserAltSlash,
  faSave,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Perfil from "../componentes/perfil";
import Sesiones from "../componentes/sesiones";

const Pacientes = () => {
  const {
    usuario,
    getPacientes,
    getPaciente,
    pacientes,
    paciente,
    EditUsuario,
    resedit,
    setDS,
    setPS
  } = useContext(UsuarioContext);

  const [edatos, setedatos] = useState(true);
  const [bdatos, setbdatos] = useState(true);
  const [gdatos, setgdatos] = useState(true);
  const [cdatos, setcdatos] = useState(true);
  const [editardatos, seteditardatos] = useState(true);

  const [seleccionado, setSeleccionado] = useState(0);

  const [Elpaciente, setElpaciente] = useState({
    id: "",
    usuario: "",
    password: "",
    nombre: "",
    correo: "",
    telefono: "",
    rol: "",
    estatus: "",
  });

  const EditDatos = () => {
    seteditardatos(false);
    setedatos(true);
    setbdatos(true);
    setgdatos(false);
    setcdatos(false);
  };
  const CancelarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
  };
  const BorrarDatos = () => {
    seteditardatos(true);
    setedatos(true);
    setbdatos(true);
    setgdatos(true);
    setcdatos(true);
    EditUsuario({ ...Elpaciente, estatus: "0" });
  };
  const GuardarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
    EditUsuario(Elpaciente);
  };

  const pacienteCambia = (e) => {
    setElpaciente({ ...Elpaciente, [e.target.name]: e.target.value });
  };

  const pacienteSeleccionado = (e) => {
    const id = e.target.value;
    setSeleccionado(id);
    setPS(id);
    setElpaciente({
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      correo: "",
      telefono: "",
      rol: "",
      estatus: "",
    });

    getPaciente(usuario[0].id, usuario[0].rol, id);
 
  };

  useEffect(() => {
    if (paciente.length > 0) {
      setbdatos(false);
      setcdatos(true);
      setedatos(false);
      setgdatos(true);
      seteditardatos(true);
      setElpaciente(paciente[0]);
    } else {
      setbdatos(true);
      setcdatos(true);
      setedatos(true);
      setgdatos(true);
      seteditardatos(true);
    }
  }, [paciente]);



  useEffect(() => {
    getPacientes(usuario[0].id, usuario[0].rol,1);
    setSeleccionado(0);
    setElpaciente({
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      correo: "",
      telefono: "",
      rol: "",
      estatus: "",
    });
  }, [resedit]);

  useEffect(() => {
    getPacientes(usuario[0].id, usuario[0].rol,1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

React.useEffect(() => {
setPS(0)
setDS(0)
},[])

  return (
    <>
     <div className="d-flex justify-content-center p-0 m-0 pt-5 mt-5">
      <Container>
        <Row className=" justify-content-between">
          <Col
            md={12}
            className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
          >
            <Row className="d-flex">
              <Col md={4}>
                <div className="text-muted fs-4 text-center">
                  Datos del Paciente
                </div>
              </Col>
              <Col md={4}>
                <Form.Select
                  value={seleccionado}
                  className=" form-control fs-6 fw-medium p-1 m-1"
                  onChange={pacienteSeleccionado}
                >
                  <option value={0}>Selecciona Paciente</option>
                  {pacientes.map((elemento) => {
                    return (
                      <option key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={4}>
                <Row className=" justify-content-end">
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={edatos}
                      onClick={EditDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faPencil} /> Editar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={bdatos}
                      onClick={BorrarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faUserAltSlash} /> Borrar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={gdatos}
                      onClick={GuardarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Guardar
                    </Button>
                  </Col>
                  <Col className="col-6">
                    <Button
                      className="btn-block"
                      hidden={cdatos}
                      onClick={CancelarDatos}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faCancel} /> Cancelar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Usuario"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="usuario"
                    value={Elpaciente.usuario}
                    type="text"
                    placeholder="Usuario"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
            
              <Col md={3}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Nombre completo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="nombre"
                    value={Elpaciente.nombre.toUpperCase()}
                    type="text"
                    minLength={20}
                    placeholder="Nombre completo"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={3}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Correo"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="correo"
                    value={Elpaciente.correo}
                    type="email"
                    placeholder="nombre@dominio.com"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={2}>
                <FloatingLabel
                  className="fs-6 fw-bolder p-1 m-1"
                  label="Teléfono"
                >
                  <Form.Control
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    name="telefono"
                    value={Elpaciente.telefono}
                    type="number"
                    placeholder="##-####-####"
                    onChange={pacienteCambia}
                    minLength={10}
                    readOnly={editardatos}
                  />
                </FloatingLabel>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3" controlId="rol">
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Tipo de usuario"
                  >
                    <Form.Select
                      className=" form-control fs-6 fw-medium p-1 m-1"
                      value={Elpaciente.rol}
                      name="rol"
                      onChange={pacienteCambia}
                      disabled={editardatos}
                    >
                      <option value="0">Selecciona Tipo</option>
                      <option value="1">Paciente</option>
                      <option value="2">Terapeuta</option>
                      <option value="3">Administrador</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col
            md={12}
            className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
          >
            <Perfil seleccionado={seleccionado} />
          </Col>
          <Col
            md={12}
            className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
          >
            <Diagnosticos seleccionado={seleccionado} />
          </Col>
          <Col>
            <Row>
              <Col
                md={12}
                className=" bg-light border border-secondary rounded-2 p-2 m-0  shadow"
              >
                <Sesiones />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
};

export default Pacientes;
